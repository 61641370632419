<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Litsenziya ozgartirish</h3>
            </div>
          </div>
          <div class="card-body">
            <form>
              <v-autocomplete
                v-model="truckid"
                :items="allTrucks"
                label="Mashina raqami"
                item-text="full__name"
                item-value="id"
                dense
                outlined
              ></v-autocomplete>
              <v-autocomplete
                v-model="driver"
                :items="allDriverNames"
                label="Haydovchi nomi"
                item-text="full__name"
                item-value="id"
                dense
                outlined
              ></v-autocomplete>
              <div class="form-group">
                <label>Amal qilish Sanasi</label>
                <div>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs1 }">
                      <input
                        type="text"
                        v-model="date"
                        v-bind="attrs1"
                        v-on="on"
                        placeholder="Berilgan sana"
                        readonly
                        class="form-control"
                      />
                    </template>
                    <v-date-picker v-model="date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false"
                        >Yopish</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </div>
              </div>
              <div>
                <b-button variant="success" class="w-100" @click="submit()">
                  Yuborish</b-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: '',
      modal: false,
      truckid: '',
      truck: '',
      driver: ''
    }
  },
  beforeCreate() {
    this.$store.dispatch('getTruckLists')
  },
  created() {
    this.$store
      .dispatch('getDriverPermitsDetails', this.$route.params.id)
      .then((res) => {
        if (res) {
          const data = this.$store.state.requests.driverPermitsDetails
          this.date = data.expires
          this.driver = data.driver
          this.truckid = data.truck_info.id
        }
      })
  },
  computed: {
    // first combobox
    allTrucks() {
      const dataNames = []

      this.$store.state.requests.allTruckList.forEach((element) => {
        const name = {
          full__name: element.reg_number_region + ' ' + element.reg_number,
          id: element.id
        }
        dataNames.push(name)
      })
      return dataNames
    },
    // 2td ccombobox
    allDriverNames() {
      const data = this.$store.state.requests.allDrivers
      // data.forEach(element => {
      //   if (element.driver !== null) {
      //     element.full__name =
      //       element.driver.staff_description.staffpersonal_description
      //         .first_name +
      //       ' ' +
      //       element.driver.staff_description.staffpersonal_description.last_name
      //   } else element.full__name = null
      // })
      return data
    }
  },
  methods: {
    submit() {
      const data = {
        truck: this.truckid,
        driver: this.driver,
        expires: this.date
      }
      this.$store.dispatch('updateDriverPermits', {
        data: data,
        id: this.$route.params.id
      })
    }
  }
}
</script>

<style></style>
